<template>
  <div class="divBox">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="资讯标题"
        :rules="[
          { required: true, message: '请输入资讯标题', trigger: 'blur' },
        ]"
        prop="topicTitle"
      >
        <v-input
          placeholder="请输入资讯标题"
          :width="width"
          v-model="form.topicTitle"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="资讯状态文案"
        :rules="[{ required: true, message: '资讯状态文案', trigger: 'blur' }]"
        prop="informationCopywriting"
      >
        <v-input
          placeholder="请输入状态文案"
          :width="width"
          :maxlength="12"
          v-model="form.informationCopywriting"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="资讯封面"
        :rules="[
          {
            required: true,
            message: '请上传资讯封面图片',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="topicPicture"
      >
        <v-upload :limit="1" :imgUrls.sync="form.topicPicture" />
      </el-form-item>
      <el-form-item
        label="编辑类型"
        :rules="[
          { required: true, message: '请选择编辑类型', trigger: 'blur' },
        ]"
      >
        <v-radio :radioObj="radioObj" v-model="form.editType"></v-radio>
      </el-form-item>
      <el-form-item
        v-if="form.editType == 1"
        label="外部链接地址"
        :rules="[
          { required: true, message: '请选择编辑类型', trigger: 'blur' },
        ]"
        prop="externalUrl"
      >
        <v-input
          placeholder="请输入外部链接地址"
          v-model="form.externalUrl"
          :width="400"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="关闭点赞"
        v-if="form.editType != 1"
        :rules="[
          { required: true, message: '请选择是否关闭点赞', trigger: 'change' },
        ]"
        prop="closePraise"
      >
        <v-select
          :width="width"
          :options="isTopOps"
          v-model="form.closePraise"
        />
      </el-form-item>

      <el-form-item
        label="关闭评论"
        v-if="form.editType != 1"
        :rules="[
          { required: true, message: '请选择是否关闭评论', trigger: 'change' },
        ]"
        prop="closeComment"
      >
        <v-select
          :width="width"
          :options="isTopOps"
          v-model="form.closeComment"
        />
      </el-form-item>
      <el-form-item
        label="是否需要签收"
        :rules="[
          { required: true, message: '请选择是否需要签收', trigger: 'blur' },
        ]"
        prop="closeSignin"
      >
        <v-select
          :width="width"
          :options="isTopOps"
          v-model="form.closeSignin"
        />
      </el-form-item>
      <el-form-item
        label="是否置顶"
        :rules="[
          { required: true, message: '请选择是否置顶', trigger: 'blur' },
        ]"
        prop="isTop"
      >
        <v-select :width="width" :options="isTopOps" v-model="form.isTop" />
      </el-form-item>

      <el-form-item
        v-if="form.isTop === 1"
        label="置顶排序"
        :rules="[
          {
            required: true,
            message: '请输入置顶排序值',
            trigger: ['blur', 'change'],
          },
          {
            message: '必须是数字格式',
            pattern: /^[0-9]+$/,
            trigger: 'blur',
          },
        ]"
        prop="isTopSort"
      >
        <v-input
          placeholder="请输入置顶排序值"
          v-model="form.isTopSort"
          min="1"
          :width="width"
          type="number"
        ></v-input>
      </el-form-item>

      <el-form-item
        v-if="form.editType != 1"
        label="资讯内容"
        prop="topicContent"
      >
        <div id="editor">
          <v-ueditor :maxLength="20000" v-model="form.topicContent" />
        </div>
      </el-form-item>

      <el-form-item
        label="发布状态"
        :rules="[{ required: true, message: '请选择状态', trigger: 'blur' }]"
        prop="status"
      >
        <v-select :width="width" :options="statusOps" v-model="form.status" />
      </el-form-item>

      <el-form-item
        label="备注"
        :rules="[{ required: false, message: '请输入备注', trigger: 'blur' }]"
        prop="remark"
      >
        <v-input
          placeholder="备注"
          :width="width"
          v-model="form.remark"
        ></v-input>
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import {
  addTopicURL,
  updateTopicURL,
  getTalentUserListURL,
  getTopicInfoByIdURL,
} from "./api.js";
import { statusMap, setStatusOps, isTopMap, setIsTopOps } from "./map.js";

export default {
  name: "partnerForm",
  data() {
    return {
      width: 220,
      communityList: [],
      // talentTypeOps: [],
      // talentTypeMap: {},
      // talentUserOps: [],
      // talentUserMap: {},
      // isTopSortOps: [],
      isTopOps: setIsTopOps(),
      isTopMap,
      statusOps: setStatusOps(),
      statusMap,
      submitConfig: {
        queryUrl: getTopicInfoByIdURL,
        submitUrl: "",
      },
      radioObj: [
        {
          name: 0,
          value: "内部编辑",
        },
        {
          name: 1,
          value: "外部链接地址",
        },
      ],
      form: {
        id: null,
        topicType: 16,
        optUser: undefined, // 用户id
        communityId: undefined, // 园区id
        talentUserId: "",
        closeSignin: 0, //是否需要签收功能
        isTop: 0, //是否置顶
        isTopSort: undefined, // 是否置顶排序
        closeComment: 0, //关闭点赞
        closePraise: 0, //关闭评论
        topicTitle: "", // 资讯标题
        topicContent: "", // 富文本内容
        informationCopywriting: "",
        externalUrl: "",
        editType: 0,
        remark: "", // 备注
        status: undefined,
        topicPicture: "", // 资讯封面
        subPicture: "",
        // adminId: null,
      },
      communityId: null,
      onOff: true, // 是否进入编辑
    };
  },
  created() {
    const { id } = this.$route.query;
    // this.form.communityId = communityId;
    if (id !== undefined) {
      this.$nextTick(() => {
        this.$refs.formPanel.getData({
          id,
        });
      });
    }
    this.submitConfig.submitUrl =
      id !== undefined ? updateTopicURL : addTopicURL;
    this.onOff = id !== undefined;
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    changetalentType(value) {
      console.log(value, 89);
      this.form.talentUserId = "";
      this.getTalentUserList(value);
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key] || this.form[key];
      });
      if (this.form.topicPicture) {
        this.form.subPicture = this.form.topicPicture;
      }
      this.onOff = false;
    },
    submitBefore() {
      if (!this.form.topicPicture || this.form.topicPicture.length == 0) {
        this.$message.error("请上传商品封面图片");
        return;
      }

      if (!this.form.optUser) {
        this.form.optUser = this.$store.state.app.userInfo.userId;
      }

      return true;
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>

<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
